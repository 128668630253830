import React, { useEffect } from "react"
import Layout from "components/layout"
import { GlobalCover } from "components/global/cover/cover"
import { Cover } from "components/anti/cover/cover"
import SEO from "components/seo"

import cover from "../assets/img/sml.jpeg"


const ProfilePage = ({ path }) => {
    const data = {
        image: {
            sourceUrl: cover,
        },
        title: "Profile"
    }


    useEffect(() => {
        setTimeout(() => {
            if (window.gigya) {
                window.gigya.accounts.showScreenSet({
                    screenSet: 'SML-ProfileUpdate',
                    startScreen: 'gigya-update-profile-screen',
                    containerID: 'screen'
                });

            }
        }, 100)
    }, [])
    return (
        <Layout path={path} theme="dark" lang={"en"} alt>
            <SEO title={"Profile"} />
            <Cover
                variant="basic"
                theme={"dark"}
                // breadcrumb={<Breadcrumb path={breadcrumb} location={location} />}
                imgOverlay={40}
                img={data?.image?.sourceUrl}
                imgHeight="h-500px"
                title={data?.title}
                titleClassName="pt-sm-down-3 w-sm-down-250px align-cover-title pb-main"
                className="global-cover login"
            />
            <div id="screen" className="my-5" />
        </Layout>
    )
}

export default ProfilePage